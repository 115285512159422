import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../components/Heading';
import { ProjectList } from './components/ProjectList';
import { ProjectListSectionDescription } from './components/ProjectListSectionDescription';

export function ProjectListSection({ data, surveyType }) {
  return (
    <View>
      <Heading name="Project" />
      <ProjectListSectionDescription data={data} surveyType={surveyType} />
      <ProjectList data={data} />
    </View>
  );
}

ProjectListSection.propTypes = {
  data: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
};

export default ProjectListSection;
