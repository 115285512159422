import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { getSurveyType, SURVEY_TYPE } from './utils/DataUtils';
import { SurveyTemplateHeadingSection } from './components/SurveyTemplateHeadingSection/SurveyTemplateHeadingSection';
import { SurveyEmployeeListSection } from './components/employee/SurveyEmployeeListSection/SurveyEmployeeListSection';
import { ProjectListSection } from './components/ProjectListSection/ProjectListSection';
import { ProjectDocumentationUploadSection } from './components/ProjectDocumentationUploadSection/ProjectDocumentationUploadSection';
import { SurveyActivitySection } from './components/SurveyActivitySection/SurveyActivitySection';
import { EmployeeProjectMatrixSection } from './components/employee/EmployeeProjectMatixSection/EmployeeProjectMatrixSection';
import { EmployeeActivityMatrixSection } from './components/employee/EmployeeActivityMatrixSection/EmployeeActivityMatrixSection';
import { SurveyAttestationSection } from './components/SurveyAttestationSection/SurveyAttestationSection';
import { SurveyVendorListSection } from './components/vendor/SurveyVendorListSection/SurveyVendorListSection';
import { VendorProjectMatrixSection } from './components/vendor/VendorProjectMatrixSection/VendorProjectMatrixSection';
import { VendorActivityMatrixSection } from './components/vendor/VendorActivityMatrixSection/VendorActivityMatrixSection';
import { BusinessComponentSection } from './components/BusinessComponentSection/BusinessComponentSection';
import { EmployeeActivityDualAllocationMatrixSection } from './components/employee/EmployeeActivityMatrixSection/EmployeeActivityDualAllocationMatrixSection';

const styles = StyleSheet.create({
  page: {
    padding: '5vh',
  },
});

const getSurveyPDFTemplateData = (surveyType, data, page) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR_DUAL:
      return (
        <Document>
          <Page size="A4" style={page}>
            <SurveyTemplateHeadingSection data={data} />
            <SurveyEmployeeListSection data={data} />
            <BusinessComponentSection data={data} surveyType={surveyType} />
            <EmployeeActivityDualAllocationMatrixSection data={data} />
            <SurveyAttestationSection data={data} />
          </Page>
        </Document>
      );
    case SURVEY_TYPE.LABOR:
    default:
      return (
        <Document>
          <Page size="A4" style={page}>
            <SurveyTemplateHeadingSection data={data} />
            <SurveyEmployeeListSection data={data} />
            <ProjectListSection data={data} surveyType={surveyType} />
            <ProjectDocumentationUploadSection data={data} />
            <SurveyActivitySection data={data} surveyType={surveyType} />
            <EmployeeProjectMatrixSection data={data} />
            <EmployeeActivityMatrixSection data={data} />
            <SurveyAttestationSection data={data} />
          </Page>
        </Document>
      );

    case SURVEY_TYPE.CONTRACT:
      return (
        <Document>
          <Page size="A4" style={page}>
            <SurveyTemplateHeadingSection data={data} />
            <SurveyVendorListSection data={data} />
            <ProjectListSection data={data} surveyType={surveyType} />
            <SurveyActivitySection data={data} surveyType={surveyType} />
            <VendorProjectMatrixSection data={data} />
            <VendorActivityMatrixSection data={data} />
            <SurveyAttestationSection data={data} />
          </Page>
        </Document>
      );
  }
};

const getSurveyPDFTemplateDataForCountryWise = (country, surveyType, data, page) => {
  switch (country) {
    case 'USA':
      return getSurveyPDFTemplateData(surveyType, data, page);
    case 'UK':
      return (
        <Document>
          <Page size="A4" style={page}>
            <SurveyTemplateHeadingSection data={data} />
            <SurveyEmployeeListSection data={data} />
            <ProjectListSection data={data} surveyType={surveyType} />
            <ProjectDocumentationUploadSection data={data} />
            <SurveyActivitySection data={data} surveyType={surveyType} />
            <EmployeeProjectMatrixSection data={data} />
            <EmployeeActivityDualAllocationMatrixSection data={data} surveyType={surveyType} />
            <SurveyAttestationSection data={data} />
          </Page>
        </Document>
      );
    default:
      return getSurveyPDFTemplateData(surveyType, data, page);
  }
};

export function SurveyPDFTemplate({ data }) {
  const { page } = styles;
  const surveyType = getSurveyType(data);
  const country = process.env.REACT_APP_CLIENT_ID.includes('UK') ? 'UK' : 'USA';
  return getSurveyPDFTemplateDataForCountryWise(country, surveyType, data, page);
}

SurveyPDFTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyPDFTemplate;
